import { useState } from 'react';
import { observer } from 'mobx-react';
import { FiChevronDown } from 'react-icons/fi';
import { Anchor } from '@strategies/react-anchors';
import { henshu, useHenshu } from '@strategies/henshu';

import Section from '../Section';
import { ENGAGEMENT_LINK } from '../../config';

export default observer(function Welcome() {
    const { bindTo } = useHenshu();
    const [promptHidden, setPromptHidden] = useState(false);

    return (
        <div className="Welcome-wrap">
            <Section name="Welcome" index={0}>
                <henshu.richtext {...bindTo('Welcome.title')} />

                <Anchor onChange={(anchor: any) => setPromptHidden(anchor.placement === 'offscreen')} />

                <henshu.p {...bindTo('Welcome.body')} />

                {/*
                <henshu.a
                    className="button with-arrow"
                    href={ENGAGEMENT_LINK}
                    {...bindTo('engagement.button')}
                />
                */}
                <henshu.a
                    className="button with-arrow down"
                    href={'#morris'}
                    {...bindTo('process.morris')}
                />
                {/* <henshu.a
                    className="button with-arrow down"
                    href={'#crookston'}
                    {...bindTo('process.crookston')}
                />
                <henshu.a
                    className="button with-arrow down"
                    href={'#duluth'}
                    {...bindTo('process.duluth')}
                /> */}
                <henshu.a
                    className="button with-arrow down"
                    href={'#resources'}
                    {...bindTo('process.viewallcampusplans')}
                />
                
            </Section>

            <footer
                className={promptHidden ? 'hidden' : ''}
                onClick={() => window.scrollTo({ top: window.innerHeight - 100, left: 0, behavior: 'smooth' })}
            >
                <FiChevronDown />
            </footer>
        </div>
    );
});
